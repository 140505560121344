import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { MAKE_OPTIONS } from "../../api/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import AppContainer from "../common/AppContainer";
import Select from "../common/Select";
import HelpLabel from "../common/HelpLabel";
import PageHeader from "../common/PageHeader";
import TextField from "../common/TextField";
import DatePicker from "../common/DatePicker";
import CheckboxGroup from "../common/CheckboxGroup";
import Checkbox from "../common/Checkbox";
import { createSelector } from "../common/orm";

const styles = theme => ({
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted"
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    button: {
        justifyContent: "left"
    },
    flex: {
        flex: 1
    },  
    minWidth: {
        minWidth: "80%"
    },
    ok: {
        '& td': {
          whiteSpace: "nowrap",
          //paddingRight: 16
        },
        '& div': {
          textAlign: "right",
          height: 32,
          marginTop: -3
        },
        '& td:not(:first-child)': {
          maxWidth: 75,
          textAlign: "center",
          paddingLeft: 2,
          paddingRight : 2
        },
    }
});

const getUsers = createSelector(schema => {
    return schema.User.all()
        .orderBy("name", "asc")
        .toRefArray();
});

function sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("myTable2");
    switching = true;
    // Set the sorting direction to ascending:
    dir = "asc";
    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
      first, which contains table headers): */
      for (i = 1; i < (rows.length - 1); i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        /* Check if the two rows should switch place,
        based on the direction, asc or desc: */
        if (dir === "asc") {
            if (n === 8 || n === 9) {
                if (parseInt(x.innerHTML.toLowerCase().replaceAll("$", "").replaceAll(",","")) > parseInt(y.innerHTML.toLowerCase().replaceAll("$", "").replaceAll(",",""))) {
                  // If so, mark as a switch and break the loop:
                  shouldSwitch = true;
                  break;
                }
            } else {
                if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                  // If so, mark as a switch and break the loop:
                  shouldSwitch = true;
                  break;
                }
            }
        } else if (dir === "desc") {
            if (n === 8 || n === 9) {
                if (parseInt(x.innerHTML.toLowerCase().replaceAll("$", "").replaceAll(",","")) < parseInt(y.innerHTML.toLowerCase().replaceAll("$", "").replaceAll(",",""))) {
                  // If so, mark as a switch and break the loop:
                  shouldSwitch = true;
                  break;
                }
            } else {
                if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                  // If so, mark as a switch and break the loop:
                  shouldSwitch = true;
                  break;
                }
            }
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount ++;
      } else {
        /* If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again. */
        if (switchcount === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }
  function printDiv(divID) {
    var divToPrint=document.getElementById(divID);
    var newWin= window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();

}

class AdminHome extends Component {
    state = {
        report: null,
    };

    componentDidMount() {
        document.title = "Administration Home - LSOHC Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && (authState.user.role !== "admin" && authState.user.role !== "readonly")) {
            history.push("/dashboard");
        }
    };

    render() {
        const { classes, users, authState, history } = this.props;
        const { report } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <PageHeader title="Administration Home" />
                    <Grid item xs={12}>
                        <ExpansionPanel defaultExpanded={true}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <GroupAddIcon className={classes.expansionIcon} /> System Settings
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/settings">
                                            Configure System Settings
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button fullWidth className={classes.button} component={Link} to="/adm/users">
                                            Manage General Users
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/table">
                                            Admin Table
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/messages">
                                            Configure User Dashboard Messages
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "api"})}>
                                            API Export
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel defaultExpanded={true}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} /> Final Reports
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "report_card"})}>
                                            Appropriation Year Report Card
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "summary"})}>
                                            Final Report Summary
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => window.open("/reports/admin/final/summarytable")}>
                                            Final Report Table Summary
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => window.open("/reports/admin/final/progress")}>
                                            Program Progress to Date
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel defaultExpanded={true}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} /> Map
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "parcel_map"})}>
                                            Batch Parcel Map
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel defaultExpanded={true}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} /> Data Pull
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "criteria_check"})}>
                                            Criteria Check
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "crops"})}>
                                            Crops Report
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "federal_match"})}>
                                            Federal Match Report
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "proposal_reports"})}>
                                            Proposal Reports
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "analysis"})}>
                                            Analysis
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => window.open("/reports/admin/data/dnrcontract")}>
                                            DNR Contract Management
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button fullWidth className={classes.button} component={Link} to="/adm/search">
                                            Advanced Search
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "queries"})}>
                                            Queries
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => window.open("/reports/admin/data/retroactive")}>
                                            Retroactive Amendments
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "bipoc"})}>
                                            BIPOC Q
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => window.open("/reports/admin/data/legchange")}>
                                            Leg change / Extensions
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "partnership"})}>
                                            Partnership Budget
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "batchstatus"})}>
                                            Batch Status Update
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "dss"})}>
                                            DSS/Person
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "achieve"})}>
                                            Status Update Achieve Goals
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel defaultExpanded={true}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} /> Parcel Reports
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "land_acquisition"})}>
                                            Land Acquisition Report Export
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "real_property"})}>
                                            Annual Real Property Interest Report
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "parcel_conveyance"})}>
                                            Parcel Conveyance Report
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "completed_acquisition"})}>
                                            Completed Acquisition by County to date
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "transfer_parcel"})}>
                                            Transfer Parcel Reporting
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "parcel_query"})}>
                                            Parcel Query
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => window.open("/reports/admin/data/parcel_shapefile")}>
                                            Download Parcel Shapefile
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "parcellookup"})}>
                                            Parcel Lookup
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            onClick={() => this.setState({report: "countynotification"})}>
                                            County Notification Parcel List
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
                <SelectDialog
                    open={report}
                    returnDialog={() => this.setState({ report: null })}
                    classes={classes}
                    users={users}
                    authState={authState}
                    history={history}
                />
            </AppContainer>
        );
    }
}


export function MAKE_OPTIONS_USER(table) {
    table = table.filter(n => n);
    var t = table.map(row => ({
        label: row.name + " (" + row.id + ")",
        value: row.id
    }));
    return t;
}

class SelectDialog extends Component {
    state = {
        has_results: null,
        loading: false
    };

    openReport = (values) => {
        const { returnDialog, open, authState, history } = this.props;
        const _this = this;

        if (open === "land_acquisition")
            window.open("/reports/admin/parcel/land_acquisition/" + values.year + "/" + values.type);
        else if (open === "real_property")
            window.open("/reports/admin/parcel/real_property/" + values.year);
        else if (open === "bipoc")
            window.open("/reports/admin/data/bipoc/" + values.year);
        else if (open === "partnership")
            window.open("/reports/admin/data/partnership/" + values.year);
        else if (open === "achieve")
            window.open("/reports/admin/data/achieve");
        else if (open === "batchstatus")
            window.open("/reports/admin/data/batchstatus/" + values.date1.split(" ")[0] + "/" + values.date2.split(" ")[0]);
        else if (open === "report_card")
            window.open("/reports/admin/final/report_card/" + values.year);
        else if (open === "summary")
            window.open("/reports/admin/final/summary/" + values.year);
        else if (open === "api")
            window.open("/reports/admin/api_export/" + values.year + "/" + values.type + "/" + values.ap);
        else if (open === "parcel_map")
            window.open("/reports/admin/parcel_map/" + values.year + "/" + values.type + "/" + values.parcel_type + "/" + values.area);
        else if (open === "parcel_conveyance")
            window.open("/reports/admin/parcel/conveyance");
        else if (open === "completed_acquisition")
            window.open("/reports/admin/parcel/completed_acquisition"); 
        else if (open === "parcel_query")
            window.open("/reports/admin/parcel/query/" + values.year + "/" + values.type + "/" + values.county + "/" + values.status + "/" + values.ap + "/" + values.area); 
        else if (open === "criteria_check")
            window.open("/reports/admin/data/criteria_check/" + values.year + "/" + values.ap);
        else if (open === "crops")
            window.open("/reports/admin/data/crops/" + values.year + "/" + values.ap);
        else if (open === "federal_match")
            window.open("/reports/admin/data/federal_match/" + values.year + "/" + values.ap);
        else if (open === "proposal_reports")
            window.open("/reports/admin/data/proposal_report/" + values.year + "/" + values.type);
        else if (open === "analysis")
            window.open("/reports/admin/data/analysis/" + values.year + "/" + values.type);
        else if (open === "countynotification")
            window.open("/reports/admin/data/countynotification/" + values.year);
        else if (open === "queries") {
            var checkboxes = values.group_1.concat(values.group_2);
            ["restore_wetland_acres", "restore_prairie_acres", "restore_forest_acres", "restore_habitat_acres", "restore_total_acres",
            "pilt_wetland_acres", "pilt_prairie_acres", "pilt_forest_acres", "pilt_habitat_acres", "pilt_total_acres",
            "wopilt_wetland_acres", "wopilt_prairie_acres", "wopilt_forest_acres", "wopilt_habitat_acres", "wopilt_total_acres",
            "easement_wetland_acres", "easement_prairie_acres", "easement_forest_acres", "easement_habitat_acres", "easement_total_acres",
            "enhance_wetland_acres", "enhance_prairie_acres", "enhance_forest_acres", "enhance_habitat_acres", "enhance_total_acres",
            "total_wetland_acres", "total_prairie_acres", "total_forest_acres", "total_habitat_acres", "total_total_acres", 
            "restore_native", "pilt_native", "wopilt_native", "easement_native", "enhance_native", "total_native",
            "restore_wetland_dollars", "restore_prairie_dollars", "restore_forest_dollars", "restore_habitat_dollars", "restore_total_dollars",
            "pilt_wetland_dollars", "pilt_prairie_dollars", "pilt_forest_dollars", "pilt_habitat_dollars", "pilt_total_dollars",
            "wopilt_wetland_dollars", "wopilt_prairie_dollars", "wopilt_forest_dollars", "wopilt_habitat_dollars", "wopilt_total_dollars",
            "easement_wetland_dollars", "easement_prairie_dollars", "easement_forest_dollars", "easement_habitat_dollars", "easement_total_dollars",
            "enhance_wetland_dollars", "enhance_prairie_dollars", "enhance_forest_dollars", "enhance_habitat_dollars", "enhance_total_dollars",
            "total_wetland_dollars", "total_prairie_dollars", "total_forest_dollars", "total_habitat_dollars", "total_total_dollars", 
            "restore_metro_acres", "restore_forestprairie_acres", "restore_seforest_acres", "restore_p_acres", "restore_nforest_acres", "restore_t_acres",
            "pilt_metro_acres", "pilt_forestprairie_acres", "pilt_seforest_acres", "pilt_p_acres", "pilt_nforest_acres", "pilt_t_acres",
            "wopilt_metro_acres", "wopilt_forestprairie_acres", "wopilt_seforest_acres", "wopilt_p_acres", "wopilt_nforest_acres", "wopilt_t_acres",
            "easement_metro_acres", "easement_forestprairie_acres", "easement_seforest_acres", "easement_p_acres", "easement_nforest_acres", "easement_t_acres",
            "enhance_metro_acres", "enhance_forestprairie_acres", "enhance_seforest_acres", "enhance_p_acres", "enhance_nforest_acres", "enhance_t_acres",
            "total_metro_acres", "total_forestprairie_acres", "total_seforest_acres", "total_p_acres", "total_nforest_acres", "total_t_acres",
            "restore_metro_dollars", "restore_forestprairie_dollars", "restore_seforest_dollars", "restore_p_dollars", "restore_nforest_dollars", "restore_t_dollars",
            "pilt_metro_dollars", "pilt_forestprairie_dollars", "pilt_seforest_dollars", "pilt_p_dollars", "pilt_nforest_dollars", "pilt_t_dollars",
            "wopilt_metro_dollars", "wopilt_forestprairie_dollars", "wopilt_seforest_dollars", "wopilt_p_dollars", "wopilt_nforest_dollars", "wopilt_t_dollars",
            "easement_metro_dollars", "easement_forestprairie_dollars", "easement_seforest_dollars", "easement_p_dollars", "easement_nforest_dollars", "easement_t_dollars",
            "enhance_metro_dollars", "enhance_forestprairie_dollars", "enhance_seforest_dollars", "enhance_p_dollars", "enhance_nforest_dollars", "enhance_t_dollars",
            "total_metro_dollars", "total_forestprairie_dollars", "total_seforest_dollars", "total_p_dollars", "total_nforest_dollars", "total_t_dollars",
            ].forEach(function(v) {
                if (values[v])
                    checkboxes.push(v);
            });
            window.open("/reports/admin/data/queries/" + values.year + "/" + values.type + "/" + checkboxes.toString());
        }
        else if (open === "transfer_parcel") {
            fetch("/reports/admin/parcel/transfer/" + values.transfer_from + "/" + values.transfer_to, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                }
            }).then(e => {
                if (e.status === 401) {
                    history.push("/");
                } else {
                    e.json().then(data => {
                        alert(data.parcels + " parcels have been transferred from user " + values.transfer_from + " to user " + values.transfer_to + ".");
                    });
                }
            });
        }
        else if (open === "parcellookup") {
            this.setState({has_results: null, loading: true});
            fetch("/reports/admin/parcel/parcellookup/" + (values.name ? encodeURIComponent(values.name) : "null") + "/" + (values.pin ? encodeURIComponent(values.pin) : "null"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                }
            }).then(e => {
                if (e.status === 401) {
                    history.push("/");
                } else {
                    e.json().then(data => {
                        _this.setState({has_results: data.parcels, loading: false});
                    });
                }
            });
        }
        if (open !== "parcellookup") {
            returnDialog();
        }
    }

    errorValidator = values => {
        const { open } = this.props;
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {};

        if (open !== "parcel_conveyance" && open !== "batchstatus" && open !== "achieve" && open !== "completed_acquisition" && open !== "transfer_parcel" && open !== "parcellookup") {
            valObj["year"] = isRequired(values.year);
        }
        
        if (open === "land_acquisition" || open === "api" || open === "proposal_reports" || open === "analysis" || open === "queries") {
            valObj["type"] = isRequired(values.type);
        }
        
        if (open === "parcel_query") {
            valObj["type"] = isRequired(values.type);
            valObj["county"] = isRequired(values.county);
            valObj["status"] = isRequired(values.status);
            valObj["ap"] = isRequired(values.ap);
            valObj["area"] = isRequired(values.area);
        }
        
        if (open === "transfer_parcel") {
            valObj["transfer_from"] = isRequired(values.transfer_from);
            valObj["transfer_to"] = isRequired(values.transfer_to);
        }

        if (open === "parcel_map") {
            valObj["type"] = isRequired(values.type);
            valObj["parcel_type"] = isRequired(values.parcel_type);
            valObj["area"] = isRequired(values.area);
        }
        
        if (open === "criteria_check" || open === "crops" || open === "federal_match" || open === "api") {
            valObj["ap"] = isRequired(values.ap);
        }
        
        /*if (open === "parcellookup") {
            valObj["name"] = isRequired(values.name);
        }*/
        
        if (open === "batchstatus") {
            valObj["date1"] = isRequired(values.date1);
            valObj["date2"] = isRequired(values.date2);
        }

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, users } = this.props;
        const { has_results, loading } = this.state;

        var date = new Date().getFullYear() + 1;
        var i = 2009;
        var options = [];
        while (i <= date) {
            options.push(i.toString());
            i++;
        }
        var defaultV = {"group_1":["Year","Subd","Title"]};
        if (open === "land_acquisition" || open === "parcel_map" || open === "parcel_query" || open === "partnership") {
            options.push("All");
            defaultV = {"year": "All", "group_1":["Year","Subd","Title"]}
        }
        if (open === "queries") {
            options.unshift("All");
        }

        return (
            <Dialog open={open}  classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        Select Parameters
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent style={{marginTop:-24}}>
                    <DialogContentText>
                        <Form
                            getApi={el => (this.form = el)}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={defaultV}
                            validateError={this.errorValidator}
                            onSubmit={values => this.openReport(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        {(open === "countynotification" || open === "analysis" || open === "partnership" || open === "dss" || open === "bipoc" || open === "proposal_reports" || open === "criteria_check" || open === "crops" || open === "federal_match" || open === "report_card" || open === "summary" || open === "api" || open === "parcel_map" || open === "parcel_query") && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS(options)} field="year" label="Appropriation Year" fullWidth />
                                            </Grid>
                                        )}
                                        {open === "batchstatus" && (
                                            <>
                                            <Grid item xs={12}>
                                                <DatePicker field="date1" fullWidth label="From" />    
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DatePicker field="date2" fullWidth label="To" />    
                                            </Grid>
                                            </>
                                        )}
                                        {open === "real_property" && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS(options)} field="year" label="Reporting Year" fullWidth />
                                            </Grid>
                                        )}
                                        {open === "analysis" && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS(["Submitted Proposals", "Funding Selected Proposals", "All Proposals", "All Accomplishment Plans", "All Final Reports"])} field="type" fullWidth label="Type" />
                                            </Grid>
                                        )}
                                        {(open === "api") && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS(["Accomplishment Plan", "Final Report"])} field="ap" fullWidth label="AP or Final Report" />
                                            </Grid>
                                        )}
                                        {(open === "criteria_check" || open === "crops" || open === "federal_match") && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS(["Accomplishment Plan", "Proposal"])} field="ap" fullWidth label="AP or Proposal" />
                                            </Grid>
                                        )}
                                        {(open === "proposal_reports") && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS(["Sustain Maintain", "Leverage", "Scale Ability", "DSS"])} field="type" fullWidth label="Type" />
                                            </Grid>
                                        )}
                                        {open === "transfer_parcel" && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS_USER(users)} field="transfer_from" label="Transfer From" fullWidth />
                                                <Select options={MAKE_OPTIONS_USER(users)} field="transfer_to" label="Transfer To" fullWidth />
                                            </Grid>
                                        )}
                                        {open === "land_acquisition" && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS(options)} field="year" label="Final Report M.L. Year" fullWidth />
                                                <Select options={MAKE_OPTIONS(["Fee", "Easement", "Restore/Enhance"])} field="type" label="Parcel Type" fullWidth />
                                            </Grid>
                                        )}
                                        {(open === "parcellookup") && (
                                            <>
                                            <Grid item xs={12}>
                                                <TextField  field="name" fullWidth label="Parcel Name" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="pin" fullWidth label="Parcel PIN" />
                                            </Grid>
                                            </>
                                        )}
                                        {(open === "parcellookup") && loading && (
                                            <Grid item xs={12} style={{textAlign:"center"}}>
                                                <CircularProgress size={30} style={{marginLeft:"auto", marginRight:"auto"}} />
                                            </Grid>
                                        )}
                                        {(open === "parcellookup") && has_results && (
                                            <Grid item xs={12}>
                                                Results:
                                                <input type="button" value="Print Table" onClick={() => printDiv("myTable2")} />
                                                <table  id="myTable2" style={{borderCollapse: "collapse", color: "#666"}}>
                                                    <tr>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(0)}>ML Year</th>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(1)}>Project Name</th>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(2)}>Project Manager</th>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(3)}>Parcel Name</th>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(4)}>Activity</th>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(5)}>Status</th>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(6)}>County</th>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(7)}>TRDS</th>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(8)}>Acres</th>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(9)}>Cost Estimate</th>
                                                        <th style={{cursor:"pointer"}} onClick={() => sortTable(10)}>PIN</th>
                                                    </tr>
                                                {has_results.map((r) => {
                                                    return (
                                                        <tr>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",padding:4}}>{r.ml_year}</td>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",padding:4}}>{r.project_name}</td>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",padding:4}}>{r.project_manager}</td>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",padding:4}}>{r.parcel_name}</td>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",padding:4}}>{r.activity}</td>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",padding:4}}>{r.status}</td>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",padding:4}}>{r.county}</td>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",padding:4}}>{r.trds}</td>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",textAlign:"right",padding:4}}>{r.acres}</td>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",textAlign:"right",padding:4}}>${parseInt(r.cost_estimate).toLocaleString()}</td>
                                                            <td style={{borderTop:"1px solid",borderLeft:"1px dotted",borderRight:"1px dotted",padding:4}}>{r.pin}</td>
                                                        </tr>
                                                    )
                                                })}
                                                </table>
                                            </Grid>
                                        )}
                                        {open === "api" && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS(["Excel", "CSV"])} field="type" fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Type"
                                                        showLabel={true}
                                                        helpText="Generate a excel document to proof check the data. Generate a csv file which then can be sent to Lee for upload."
                                                    />
                                                </Select>
                                            </Grid>
                                        )}
                                        {open === "parcel_map" && (
                                            <Grid item xs={12}>
                                                <Select
                                                    options={[
                                                        { label: "Submitted Proposal Parcels", value: "submittedparcels" },
                                                        { label: "Approved Proposal Parcels", value: "approvedparcels" },
                                                        { label: "All Accomplishment Parcels", value: "accomplishmentparcels" },
                                                        { label: "Completed Parcels", value: "completedparcels" }
                                                    ]} field="type" fullWidth label="Type" />

                                                <CheckboxGroup
                                                    options={[
                                                        { label: "All", value: "typeAll" },
                                                        { label: "Easement", value: "typeEasement" },
                                                        { label: "Fee with PILT", value: "typeFeePilt" },
                                                        { label: "Fee W/O PILT", value: "typeFee" },
                                                        { label: "Restore", value: "typeRestore" },
                                                        { label: "Enhance", value: "typeEnhance" }
                                                    ]} field="parcel_type" row={true} fullWidth label="Parcel Type" />
                                                <Select
                                                    options={MAKE_OPTIONS([ "Statewide", "7 - County Metro", "11 - County Metro", "Senate District 01", "Senate District 02", "Senate District 03", "Senate District 04",
                                                        "Senate District 05", "Senate District 06", "Senate District 07", "Senate District 08", "Senate District 09", "Senate District 10", "Senate District 11", "Senate District 12", "Senate District 13", "Senate District 14", "Senate District 15", "Senate District 16", "Senate District 17", "Senate District 18", "Senate District 19", "Senate District 20", "Senate District 21", "Senate District 22", "Senate District 23", "Senate District 24", "Senate District 25", "Senate District 26", "Senate District 27", "Senate District 28", "Senate District 29",
                                                        "Senate District 30", "Senate District 31", "Senate District 32", "Senate District 33", "Senate District 34", "Senate District 35", "Senate District 36", "Senate District 37", "Senate District 38", "Senate District 39", "Senate District 40", "Senate District 41", "Senate District 42", "Senate District 43", "Senate District 44", "Senate District 45", "Senate District 46",
                                                        "Senate District 47", "Senate District 48", "Senate District 49", "Senate District 50", "Senate District 51", "Senate District 52", "Senate District 53", "Senate District 54", "Senate District 55", "Senate District 56", "Senate District 57", "Senate District 58", "Senate District 59", "Senate District 60", "Senate District 61", "Senate District 62", "Senate District 63", "Senate District 64", "Senate District 65", "Senate District 66", "Senate District 67", "House District 01A", "House District 01B", "House District 02A", "House District 02B", "House District 03A",
                                                        "House District 03B", "House District 04A", "House District 04B", "House District 05A", "House District 05B", "House District 06A", "House District 06B", "House District 07A", "House District 07B", "House District 08A", "House District 08B", "House District 09A", "House District 09B", "House District 10A", "House District 10B", "House District 11A", "House District 11B", "House District 12A", "House District 12B", "House District 13A", "House District 13B", "House District 14A", "House District 14B", "House District 15A", "House District 15B", "House District 16A", "House District 16B", "House District 17A", "House District 17B", "House District 18A", "House District 18B", "House District 19A", "House District 19B", "House District 20A", "House District 20B", "House District 21A", "House District 21B", "House District 22A", "House District 22B",
                                                        "House District 23A", "House District 23B", "House District 24A", "House District 24B", "House District 25A", "House District 25B", "House District 26A", "House District 26B", "House District 27A", "House District 27B", "House District 28A", "House District 28B", "House District 29A", "House District 29B", "House District 30A", "House District 30B", "House District 31A", "House District 31B", "House District 32A", "House District 32B", "House District 33A", "House District 33B", "House District 34A", "House District 34B", "House District 35A", "House District 35B",
                                                        "House District 36A", "House District 36B", "House District 37A", "House District 37B", "House District 38A", "House District 38B", "House District 39A", "House District 39B", "House District 40A", "House District 40B", "House District 41A", "House District 41B", "House District 42A", "House District 42B", "House District 43A", "House District 43B", "House District 44A", "House District 44B", "House District 45A", "House District 45B", "House District 46A", "House District 46B", "House District 47A", "House District 47B", "House District 48A", "House District 48B", "House District 49A", "House District 49B", "House District 50A", "House District 50B", "House District 51A", "House District 51B",
                                                        "House District 52A", "House District 52B", "House District 53A", "House District 53B", "House District 54A", "House District 54B", "House District 55A", "House District 55B", "House District 56A", "House District 56B", "House District 57A", "House District 57B", "House District 58A", "House District 58B", "House District 59A", "House District 59B", "House District 60A", "House District 60B", "House District 61A", "House District 61B", "House District 62A", "House District 62B", "House District 63A", "House District 63B", "House District 64A", "House District 64B", "House District 65A", "House District 65B", "House District 66A", "House District 66B", "House District 67A",
                                                        "House District 67B", "Aitkin", "Anoka", "Becker", "Beltrami", "Benton", "Big Stone", "Blue Earth", "Brown", "Carlton", "Carver", "Cass", "Chippewa", "Chisago", "Clay", "Clearwater", "Cook", "Cottonwood", "Crow Wing", "Dakota", "Dodge", "Douglas", "Fairbault", "Fillmore", "Freeborn", "Goodhue", "Grant", "Hennepin", "Houston",
                                                        "Hubbard", "Isanti", "Itasca", "Jackson", "Kanabec", "Kandiyohi", "Kittson", "Koochiching", "Lac qui Parle", "Lake", "Lake of the Woods", "Le Sueur", "Lincoln", "Lyon", "McLeod", "Mahnomen", "Marshall", "Martin", "Meeker", "Mille Lacs",
                                                        "Morrison", "Mower", "Murray", "Nicollet", "Nobles", "Norman", "Olmsted", "Otter Tail", "Pennington", "Pine", "Pipestone", "Polk", "Pope", "Ramsey", "Red Lake", "Redwood", "Renville",
                                                        "Rice", "Rock", "Roseau", "Scott", "Sherburne", "Sibley", "St. Louis", "Stearns", "Steele", "Stevens", "Swift", "Todd", "Traverse", "Wabasha", "Wadena", "Waseca", "Washington", "Watonwan", "Wilkin", "Winona", "Wright", "Yellow Medicine"])} 
                                                        field="area" fullWidth label="Area" />
                                            </Grid>
                                        )}
                                        {open === "queries" && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS([ "Proposals", "Current Accomplishment Plans", "Original Accomplishment Plans", "Final Reports"])} field="type" fullWidth label="Type" />
                                                <CheckboxGroup options={MAKE_OPTIONS(options)} field="year" row={true} fullWidth label="ML Year" />
                                                <CheckboxGroup
                                                    options={MAKE_OPTIONS([
                                                        "Year", "Subd", "Title", "Manager Name", "Manager Title", "Organization",
                                                        "Address 1", "Address 2", "City", "State", "Zip", 
                                                        "Email", "Office Phone", "Mobile Phone", "Fax", "Website", 
                                                        "Amount Spent", "Status", "User ID", "Created Date", "Modified Date", "Project Number"
                                                        ])} 
                                                        field="group_1" row={true} fullWidth label="General Columns" />
                                                <CheckboxGroup
                                                    options={MAKE_OPTIONS([
                                                        "Personnel - Funding Amount", "Personnel - Leverage Amount", 
                                                        "Contracts - Funding Amount", "Contracts - Leverage Amount",
                                                        "Fee Acquisition w  PILT - Funding Amount", "Fee Acquisition w  PILT - Leverage Amount", 
                                                        "Fee Acquisition w o PILT - Funding Amount", "Fee Acquisition w o PILT - Leverage Amount", 
                                                        "Easement Acquisition - Funding Amount", "Easement Acquisition - Leverage Amount", 
                                                        "Easement Stewardship - Funding Amount", "Easement Stewardship - Leverage Amount",
                                                        "Travel - Funding Amount", "Travel - Leverage Amount", 
                                                        "Professional Services - Funding Amount", "Professional Services - Leverage Amount", 
                                                        "Direct Support Services - Funding Amount", "Direct Support Services - Leverage Amount",
                                                        "DNR Land Acquisition Costs - Funding Amount", "DNR Land Acquisition Costs - Leverage Amount",
                                                        "Capital Equipment - Funding Amount", "Capital Equipment - Leverage Amount",
                                                        "Other Equipment Tools - Funding Amount", "Other Equipment Tools - Leverage Amount", 
                                                        "Supplies Materials - Funding Amount", "Supplies Materials - Leverage Amount", 
                                                        "DNR IDP - Funding Amount", "DNR IDP - Leverage Amount", 
                                                        "Grand Total - Funding Amount", "Grand Total - Leverage Amount"])} 
                                                        field="group_2" row={true} fullWidth label="Budget Columns" />
                                                <table className={classes.ok}>
                                                    <tr>
                                                        <td colspan="6"><b>Acres by Resource Type</b></td>
                                                    </tr><tr>
                                                        <td>Type</td>
                                                        <td>Wetlands</td>
                                                        <td>Prairies</td>
                                                        <td>Forest</td>
                                                        <td>Habitats</td>
                                                        <td>Total</td>
                                                    </tr><tr>
                                                        <td>Restore</td>
                                                        <td><Checkbox field="restore_wetland_acres" /></td>
                                                        <td><Checkbox field="restore_prairie_acres" /></td>
                                                        <td><Checkbox field="restore_forest_acres" /></td>
                                                        <td><Checkbox field="restore_habitat_acres" /></td>
                                                        <td><Checkbox field="restore_total_acres" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Fee with State PILT Liability</td>
                                                        <td><Checkbox field="pilt_wetland_acres" /></td>
                                                        <td><Checkbox field="pilt_prairie_acres" /></td>
                                                        <td><Checkbox field="pilt_forest_acres" /></td>
                                                        <td><Checkbox field="pilt_habitat_acres" /></td>
                                                        <td><Checkbox field="pilt_total_acres" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Fee W/O State PILT Liability</td>
                                                        <td><Checkbox field="wopilt_wetland_acres" /></td>
                                                        <td><Checkbox field="wopilt_prairie_acres" /></td>
                                                        <td><Checkbox field="wopilt_forest_acres" /></td>
                                                        <td><Checkbox field="wopilt_habitat_acres" /></td>
                                                        <td><Checkbox field="wopilt_total_acres" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Easement</td>
                                                        <td><Checkbox field="easement_wetland_acres" /></td>
                                                        <td><Checkbox field="easement_prairie_acres" /></td>
                                                        <td><Checkbox field="easement_forest_acres" /></td>
                                                        <td><Checkbox field="easement_habitat_acres" /></td>
                                                        <td><Checkbox field="easement_total_acres" /></td>
                                                    </tr><tr>
                                                        <td>Enhance</td>
                                                        <td><Checkbox field="enhance_wetland_acres" /></td>
                                                        <td><Checkbox field="enhance_prairie_acres" /></td>
                                                        <td><Checkbox field="enhance_forest_acres" /></td>
                                                        <td><Checkbox field="enhance_habitat_acres" /></td>
                                                        <td><Checkbox field="enhance_total_acres" /></td>
                                                    </tr><tr>
                                                        <td>Total</td>
                                                        <td><Checkbox field="total_wetland_acres" /></td>
                                                        <td><Checkbox field="total_prairie_acres" /></td>
                                                        <td><Checkbox field="total_forest_acres" /></td>
                                                        <td><Checkbox field="total_habitat_acres" /></td>
                                                        <td><Checkbox field="total_total_acres" /></td>
                                                    </tr>
                                                </table>
                                                <table className={classes.ok}>
                                                    <tr>
                                                        <td colspan="6"><b>Native Prairie</b></td>
                                                    </tr><tr>
                                                        <td>Type</td>
                                                        <td>Native Prairie</td>
                                                    </tr><tr>
                                                        <td>Restore</td>
                                                        <td><Checkbox field="restore_native" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Fee with State PILT Liability</td>
                                                        <td><Checkbox field="pilt_native" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Fee W/O State PILT Liability</td>
                                                        <td><Checkbox field="wopilt_native" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Easement</td>
                                                        <td><Checkbox field="easement_native" /></td>
                                                    </tr><tr>
                                                        <td>Enhance</td>
                                                        <td><Checkbox field="enhance_native" /></td>
                                                    </tr><tr>
                                                        <td>Total</td>
                                                        <td><Checkbox field="total_native" /></td>
                                                    </tr>
                                                </table>
                                                <table className={classes.ok}>
                                                    <tr>
                                                        <td colspan="6"><b>Total Funding by Resource Type</b></td>
                                                    </tr><tr>
                                                        <td>Type</td>
                                                        <td>Wetlands</td>
                                                        <td>Prairies</td>
                                                        <td>Forest</td>
                                                        <td>Habitats</td>
                                                        <td>Total</td>
                                                    </tr><tr>
                                                        <td>Restore</td>
                                                        <td><Checkbox field="restore_wetland_dollars" /></td>
                                                        <td><Checkbox field="restore_prairie_dollars" /></td>
                                                        <td><Checkbox field="restore_forest_dollars" /></td>
                                                        <td><Checkbox field="restore_habitat_dollars" /></td>
                                                        <td><Checkbox field="restore_total_dollars" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Fee with State PILT Liability</td>
                                                        <td><Checkbox field="pilt_wetland_dollars" /></td>
                                                        <td><Checkbox field="pilt_prairie_dollars" /></td>
                                                        <td><Checkbox field="pilt_forest_dollars" /></td>
                                                        <td><Checkbox field="pilt_habitat_dollars" /></td>
                                                        <td><Checkbox field="pilt_total_dollars" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Fee W/O State PILT Liability</td>
                                                        <td><Checkbox field="wopilt_wetland_dollars" /></td>
                                                        <td><Checkbox field="wopilt_prairie_dollars" /></td>
                                                        <td><Checkbox field="wopilt_forest_dollars" /></td>
                                                        <td><Checkbox field="wopilt_habitat_dollars" /></td>
                                                        <td><Checkbox field="wopilt_total_dollars" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Easement</td>
                                                        <td><Checkbox field="easement_wetland_dollars" /></td>
                                                        <td><Checkbox field="easement_prairie_dollars" /></td>
                                                        <td><Checkbox field="easement_forest_dollars" /></td>
                                                        <td><Checkbox field="easement_habitat_dollars" /></td>
                                                        <td><Checkbox field="easement_total_dollars" /></td>
                                                    </tr><tr>
                                                        <td>Enhance</td>
                                                        <td><Checkbox field="enhance_wetland_dollars" /></td>
                                                        <td><Checkbox field="enhance_prairie_dollars" /></td>
                                                        <td><Checkbox field="enhance_forest_dollars" /></td>
                                                        <td><Checkbox field="enhance_habitat_dollars" /></td>
                                                        <td><Checkbox field="enhance_total_dollars" /></td>
                                                    </tr><tr>
                                                        <td>Total</td>
                                                        <td><Checkbox field="total_wetland_dollars" /></td>
                                                        <td><Checkbox field="total_prairie_dollars" /></td>
                                                        <td><Checkbox field="total_forest_dollars" /></td>
                                                        <td><Checkbox field="total_habitat_dollars" /></td>
                                                        <td><Checkbox field="total_total_dollars" /></td>
                                                    </tr>
                                                </table>
                                                <table className={classes.ok}>
                                                    <tr>
                                                        <td colspan="6"><b>Acres within each Ecological Section</b></td>
                                                    </tr><tr>
                                                        <td>Type</td>
                                                        <td>Metro/Urban</td>
                                                        <td>Forest/Prairie</td>
                                                        <td>SE Forest</td>
                                                        <td>Prairie</td>
                                                        <td>Northern Forest</td>
                                                        <td>Total</td>
                                                    </tr><tr>
                                                        <td>Restore</td>
                                                        <td><Checkbox field="restore_metro_acres" /></td>
                                                        <td><Checkbox field="restore_forestprairie_acres" /></td>
                                                        <td><Checkbox field="restore_seforest_acres" /></td>
                                                        <td><Checkbox field="restore_p_acres" /></td>
                                                        <td><Checkbox field="restore_nforest_acres" /></td>
                                                        <td><Checkbox field="restore_t_acres" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Fee with State PILT Liability</td>
                                                        <td><Checkbox field="pilt_metro_acres" /></td>
                                                        <td><Checkbox field="pilt_forestprairie_acres" /></td>
                                                        <td><Checkbox field="pilt_seforest_acres" /></td>
                                                        <td><Checkbox field="pilt_p_acres" /></td>
                                                        <td><Checkbox field="pilt_nforest_acres" /></td>
                                                        <td><Checkbox field="pilt_t_acres" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Fee W/O State PILT Liability</td>
                                                        <td><Checkbox field="wopilt_metro_acres" /></td>
                                                        <td><Checkbox field="wopilt_forestprairie_acres" /></td>
                                                        <td><Checkbox field="wopilt_seforest_acres" /></td>
                                                        <td><Checkbox field="wopilt_p_acres" /></td>
                                                        <td><Checkbox field="wopilt_nforest_acres" /></td>
                                                        <td><Checkbox field="wopilt_t_acres" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Easement</td>
                                                        <td><Checkbox field="easement_metro_acres" /></td>
                                                        <td><Checkbox field="easement_forestprairie_acres" /></td>
                                                        <td><Checkbox field="easement_seforest_acres" /></td>
                                                        <td><Checkbox field="easement_p_acres" /></td>
                                                        <td><Checkbox field="easement_nforest_acres" /></td>
                                                        <td><Checkbox field="easement_t_acres" /></td>
                                                    </tr><tr>
                                                        <td>Enhance</td>
                                                        <td><Checkbox field="enhance_metro_acres" /></td>
                                                        <td><Checkbox field="enhance_forestprairie_acres" /></td>
                                                        <td><Checkbox field="enhance_seforest_acres" /></td>
                                                        <td><Checkbox field="enhance_p_acres" /></td>
                                                        <td><Checkbox field="enhance_nforest_acres" /></td>
                                                        <td><Checkbox field="enhance_t_acres" /></td>
                                                    </tr><tr>
                                                        <td>Total</td>
                                                        <td><Checkbox field="total_metro_acres" /></td>
                                                        <td><Checkbox field="total_forestprairie_acres" /></td>
                                                        <td><Checkbox field="total_seforest_acres" /></td>
                                                        <td><Checkbox field="total_p_acres" /></td>
                                                        <td><Checkbox field="total_nforest_acres" /></td>
                                                        <td><Checkbox field="total_t_acres" /></td>
                                                    </tr>
                                                </table>
                                                <table className={classes.ok}>
                                                    <tr>
                                                        <td colspan="6"><b>Funding Spent within each Ecological Section</b></td>
                                                    </tr><tr>
                                                        <td>Type</td>
                                                        <td>Metro/Urban</td>
                                                        <td>Forest/Prairie</td>
                                                        <td>SE Forest</td>
                                                        <td>Prairie</td>
                                                        <td>Northern Forest</td>
                                                        <td>Total</td>
                                                    </tr><tr>
                                                        <td>Restore</td>
                                                        <td><Checkbox field="restore_metro_dollars" /></td>
                                                        <td><Checkbox field="restore_forestprairie_dollars" /></td>
                                                        <td><Checkbox field="restore_seforest_dollars" /></td>
                                                        <td><Checkbox field="restore_p_dollars" /></td>
                                                        <td><Checkbox field="restore_nforest_dollars" /></td>
                                                        <td><Checkbox field="restore_t_dollars" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Fee with State PILT Liability</td>
                                                        <td><Checkbox field="pilt_metro_dollars" /></td>
                                                        <td><Checkbox field="pilt_forestprairie_dollars" /></td>
                                                        <td><Checkbox field="pilt_seforest_dollars" /></td>
                                                        <td><Checkbox field="pilt_p_dollars" /></td>
                                                        <td><Checkbox field="pilt_nforest_dollars" /></td>
                                                        <td><Checkbox field="pilt_t_dollars" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Fee W/O State PILT Liability</td>
                                                        <td><Checkbox field="wopilt_metro_dollars" /></td>
                                                        <td><Checkbox field="wopilt_forestprairie_dollars" /></td>
                                                        <td><Checkbox field="wopilt_seforest_dollars" /></td>
                                                        <td><Checkbox field="wopilt_p_dollars" /></td>
                                                        <td><Checkbox field="wopilt_nforest_dollars" /></td>
                                                        <td><Checkbox field="wopilt_t_dollars" /></td>
                                                    </tr><tr>
                                                        <td>Protect in Easement</td>
                                                        <td><Checkbox field="easement_metro_dollars" /></td>
                                                        <td><Checkbox field="easement_forestprairie_dollars" /></td>
                                                        <td><Checkbox field="easement_seforest_dollars" /></td>
                                                        <td><Checkbox field="easement_p_dollars" /></td>
                                                        <td><Checkbox field="easement_nforest_dollars" /></td>
                                                        <td><Checkbox field="easement_t_dollars" /></td>
                                                    </tr><tr>
                                                        <td>Enhance</td>
                                                        <td><Checkbox field="enhance_metro_dollars" /></td>
                                                        <td><Checkbox field="enhance_forestprairie_dollars" /></td>
                                                        <td><Checkbox field="enhance_seforest_dollars" /></td>
                                                        <td><Checkbox field="enhance_p_dollars" /></td>
                                                        <td><Checkbox field="enhance_nforest_dollars" /></td>
                                                        <td><Checkbox field="enhance_t_dollars" /></td>
                                                    </tr><tr>
                                                        <td>Total</td>
                                                        <td><Checkbox field="total_metro_dollars" /></td>
                                                        <td><Checkbox field="total_forestprairie_dollars" /></td>
                                                        <td><Checkbox field="total_seforest_dollars" /></td>
                                                        <td><Checkbox field="total_p_dollars" /></td>
                                                        <td><Checkbox field="total_nforest_dollars" /></td>
                                                        <td><Checkbox field="total_t_dollars" /></td>
                                                    </tr>
                                                </table>
                                            </Grid>
                                        )}
                                        {open === "parcel_query" && (
                                            <Grid item xs={12}>
                                                <Select options={MAKE_OPTIONS(["All", "Protect in Fee with PILT", "Protect in Fee wo PILT", "Protect in Easement", "Restore", "Enhance", "Other"])} field="type" row={true} fullWidth label="Parcel Type" />
                                                <Select
                                                    options={MAKE_OPTIONS(["All", "Aitkin", "Anoka", "Becker", "Beltrami", "Benton", "Big Stone", "Blue Earth", "Brown", "Carlton", "Carver", "Cass", "Chippewa", "Chisago", "Clay", "Clearwater", "Cook", "Cottonwood", "Crow Wing", "Dakota", "Dodge", "Douglas", "Fairbault", "Fillmore", "Freeborn", "Goodhue", "Grant", "Hennepin", "Houston",
                                                        "Hubbard", "Isanti", "Itasca", "Jackson", "Kanabec", "Kandiyohi", "Kittson", "Koochiching", "Lac qui Parle", "Lake", "Lake of the Woods", "Le Sueur", "Lincoln", "Lyon", "McLeod", "Mahnomen", "Marshall", "Martin", "Meeker", "Mille Lacs",
                                                        "Morrison", "Mower", "Murray", "Nicollet", "Nobles", "Norman", "Olmsted", "Otter Tail", "Pennington", "Pine", "Pipestone", "Polk", "Pope", "Ramsey", "Red Lake", "Redwood", "Renville",
                                                        "Rice", "Rock", "Roseau", "Scott", "Sherburne", "Sibley", "St. Louis", "Stearns", "Steele", "Stevens", "Swift", "Todd", "Traverse", "Wabasha", "Wadena", "Waseca", "Washington", "Watonwan", "Wilkin", "Winona", "Wright", "Yellow Medicine"])} 
                                                        field="county" fullWidth label="County" />
                                                <Select options={MAKE_OPTIONS(["All", "Proposed", "Completed"])} field="status" fullWidth label="Status" />
                                                <Select options={MAKE_OPTIONS(["Accomplishment Plan", "Proposal"])} field="ap" fullWidth label="AP or Proposal" />
                                                <Select
                                                    options={MAKE_OPTIONS(["Statewide", "7 - County Metro", "11 - County Metro", "Senate District 01", "Senate District 02", "Senate District 03", "Senate District 04",
                                                        "Senate District 05", "Senate District 06", "Senate District 07", "Senate District 08", "Senate District 09", "Senate District 10", "Senate District 11", "Senate District 12", "Senate District 13", "Senate District 14", "Senate District 15", "Senate District 16", "Senate District 17", "Senate District 18", "Senate District 19", "Senate District 20", "Senate District 21", "Senate District 22", "Senate District 23", "Senate District 24", "Senate District 25", "Senate District 26", "Senate District 27", "Senate District 28", "Senate District 29",
                                                        "Senate District 30", "Senate District 31", "Senate District 32", "Senate District 33", "Senate District 34", "Senate District 35", "Senate District 36", "Senate District 37", "Senate District 38", "Senate District 39", "Senate District 40", "Senate District 41", "Senate District 42", "Senate District 43", "Senate District 44", "Senate District 45", "Senate District 46",
                                                        "Senate District 47", "Senate District 48", "Senate District 49", "Senate District 50", "Senate District 51", "Senate District 52", "Senate District 53", "Senate District 54", "Senate District 55", "Senate District 56", "Senate District 57", "Senate District 58", "Senate District 59", "Senate District 60", "Senate District 61", "Senate District 62", "Senate District 63", "Senate District 64", "Senate District 65", "Senate District 66", "Senate District 67", "House District 01A", "House District 01B", "House District 02A", "House District 02B", "House District 03A",
                                                        "House District 03B", "House District 04A", "House District 04B", "House District 05A", "House District 05B", "House District 06A", "House District 06B", "House District 07A", "House District 07B", "House District 08A", "House District 08B", "House District 09A", "House District 09B", "House District 10A", "House District 10B", "House District 11A", "House District 11B", "House District 12A", "House District 12B", "House District 13A", "House District 13B", "House District 14A", "House District 14B", "House District 15A", "House District 15B", "House District 16A", "House District 16B", "House District 17A", "House District 17B", "House District 18A", "House District 18B", "House District 19A", "House District 19B", "House District 20A", "House District 20B", "House District 21A", "House District 21B", "House District 22A", "House District 22B",
                                                        "House District 23A", "House District 23B", "House District 24A", "House District 24B", "House District 25A", "House District 25B", "House District 26A", "House District 26B", "House District 27A", "House District 27B", "House District 28A", "House District 28B", "House District 29A", "House District 29B", "House District 30A", "House District 30B", "House District 31A", "House District 31B", "House District 32A", "House District 32B", "House District 33A", "House District 33B", "House District 34A", "House District 34B", "House District 35A", "House District 35B",
                                                        "House District 36A", "House District 36B", "House District 37A", "House District 37B", "House District 38A", "House District 38B", "House District 39A", "House District 39B", "House District 40A", "House District 40B", "House District 41A", "House District 41B", "House District 42A", "House District 42B", "House District 43A", "House District 43B", "House District 44A", "House District 44B", "House District 45A", "House District 45B", "House District 46A", "House District 46B", "House District 47A", "House District 47B", "House District 48A", "House District 48B", "House District 49A", "House District 49B", "House District 50A", "House District 50B", "House District 51A", "House District 51B",
                                                        "House District 52A", "House District 52B", "House District 53A", "House District 53B", "House District 54A", "House District 54B", "House District 55A", "House District 55B", "House District 56A", "House District 56B", "House District 57A", "House District 57B", "House District 58A", "House District 58B", "House District 59A", "House District 59B", "House District 60A", "House District 60B", "House District 61A", "House District 61B", "House District 62A", "House District 62B", "House District 63A", "House District 63B", "House District 64A", "House District 64B", "House District 65A", "House District 65B", "House District 66A", "House District 66B", "House District 67A",
                                                        "House District 67B", "Aitkin", "Anoka", "Becker", "Beltrami", "Benton", "Big Stone", "Blue Earth", "Brown", "Carlton", "Carver", "Cass", "Chippewa", "Chisago", "Clay", "Clearwater", "Cook", "Cottonwood", "Crow Wing", "Dakota", "Dodge", "Douglas", "Fairbault", "Fillmore", "Freeborn", "Goodhue", "Grant", "Hennepin", "Houston",
                                                        "Hubbard", "Isanti", "Itasca", "Jackson", "Kanabec", "Kandiyohi", "Kittson", "Koochiching", "Lac qui Parle", "Lake", "Lake of the Woods", "Le Sueur", "Lincoln", "Lyon", "McLeod", "Mahnomen", "Marshall", "Martin", "Meeker", "Mille Lacs",
                                                        "Morrison", "Mower", "Murray", "Nicollet", "Nobles", "Norman", "Olmsted", "Otter Tail", "Pennington", "Pine", "Pipestone", "Polk", "Pope", "Ramsey", "Red Lake", "Redwood", "Renville",
                                                        "Rice", "Rock", "Roseau", "Scott", "Sherburne", "Sibley", "St. Louis", "Stearns", "Steele", "Stevens", "Swift", "Todd", "Traverse", "Wabasha", "Wadena", "Waseca", "Washington", "Watonwan", "Wilkin", "Winona", "Wright", "Yellow Medicine"])} 
                                                        field="area" fullWidth label="Area" />
                                            </Grid>
                                        )}
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => returnDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Generate Report
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

AdminHome = connect(
    (state, ownProps) => ({
        authState: state.auth,
        users: getUsers(state, ownProps)
    }),
    null
)(AdminHome);

export default withStyles(styles)(withRouter(AdminHome));
